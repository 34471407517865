<!--
File: Traffic.vue
Description: form for showing the Traffic data.
-->
<template>
  <md-card>
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-20">
          <md-field>
            <md-input type="search" class="mb-3" clearable style="width: 200px" :placeholder="$t('label.search_records')"
              v-model="searchQuery"></md-input>
          </md-field>
        </div>
        <div class="md-layout-item btn-row md-small-size-100">
          <md-button class="md-success" @click="addTraficEntry"> {{ $t('buttons.add') }} </md-button>
        </div>
      </div>
    </md-card-header>

    <md-card-content>
      <md-progress-spinner v-if="showSpinner == true" class="md-progress-spinner" :md-diameter="70"
        md-mode="indeterminate" />
      <md-table class="paginated-table table-striped table-hover" :value="queriedData" :md-sort.sync="currentSort"
        :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" md-fixed-header>
        <md-table-empty-state :md-label="$t('label.no_data')" :md-description="$t('messages.select_another_criteria')" />
        <md-table-row slot="md-table-row" slot-scope="{item}">
          <md-table-cell :md-label="$t('stdCols.id')" md-sort-by="traffic_install_id">
            {{ item.traffic_install_id }}
          </md-table-cell>
          <md-table-cell :md-label="$t('stdCols.description')" md-sort-by="traffic_install_desc">
            {{ item.traffic_install_desc }}
          </md-table-cell>
          <md-table-cell :md-label="$t('traffic.site_id')" md-sort-by="fk_traffic_site">
            {{ item.fk_traffic_site }}
          </md-table-cell>
          <md-table-cell :md-label="$t('traffic.photo_filename')" md-sort-by="photo_filename">
            {{ item.photo_filename }}
          </md-table-cell>
          <md-table-cell :md-label="$t('tables.actions')">
            <div class="cell-actions">
              <md-button class="md-default md-raised md-sm" @click.native="editTraficEntry(item.traffic_install_id)">
                {{ $t('buttons.edit') }}
              </md-button>
              <md-button v-if="false" class="md-just-icon md-danger md-simple" @click.stop.prevent="handleDelete(item)">
                <md-icon>close</md-icon>
              </md-button>
            </div>
          </md-table-cell>
        </md-table-row>
      </md-table>
      <div class="footer-table md-table">
        <table>
          <tfoot>
            <tr>
              <th v-for="item in footerTable" :key="item.name" class="md-table-head">
                <div class="md-table-head-container md-ripple md-disabled">
                  <div class="md-table-head-label">{{ item }}</div>
                </div>
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
    </md-card-content>
    <md-card-actions md-alignment="space-between">
      <div class>
        <p class="card-category">
          {{
            $t('label.showing_from_to_of_entries', {
              from: to > 0 ? from + 1 : 0,
              to: to,
              total
            })
          }}
        </p>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-20">
        <md-field>
          <label for="pages">{{ $t('label.per_page') }}</label>
          <md-select v-model="pagination.perPage" name="pages">
            <md-option v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item">
              {{ item }}
            </md-option>
          </md-select>
        </md-field>
      </div>
      <pagination class="pagination-no-border pagination-success" v-model="pagination.currentPage"
        :per-page="pagination.perPage" :total="total"></pagination>
    </md-card-actions>
  </md-card>
</template>
<script>
  import { Pagination } from '@/components'
  import Fuse from 'fuse.js'

  export default {
    name: 'traffic-all',
    data() {
      return {
        showSpinner: false,
        pagination: {
          perPage: 20,
          currentPage: 1,
          perPageOptions: [10, 20, 50, 100],
          total: 0
        },
        searchQuery: '',
        propsToSearch: ['traffic_install_desc', 'fk_traffic_site', 'photo_filename'],
        searchedData: [],
        currentSort: 'traffic_install_id',
        currentSortOrder: 'asc',
        fuseSearch: null,
        footerTable: []
      }
    },
    components: {
      Pagination
    },

    mounted() {
      this.showSpinner = true
      this.$store.dispatch('LOAD_TRAFFIC_INSTALLATION').then(() => {
        // Fuse search initialization.
        this.fuseSearch = new Fuse(this.trafficList, {
          keys: this.propsToSearch,
          threshold: 0.3
        })
        this.showSpinner = false
      })
    },

    methods: {
      customSort(value) {
        return value.sort((a, b) => {
          const sortBy = this.currentSort
          const res = (!a[sortBy]) ? -1 : (!b[sortBy]) ? 1 :
            a[sortBy].toString().localeCompare(b[sortBy].toString(), undefined, { numeric: true })
          return (this.currentSortOrder === 'desc') ? -res : res
        })
      },
      editTraficEntry(traffic_install_id) {
        this.$router.push(`traffic/upd/${traffic_install_id}`)
      },
      addTraficEntry() {
        this.$router.push(`traffic/add`)
      }
    },

    computed: {
      trafficList() {
        return this.$store.state.Traffic.list
      },
      queriedData() {
        const result = !this.searchQuery ? this.trafficList : this.searchedData ? this.searchedData : []
        return result.slice(this.from, this.to)
      },
      to() {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },
      from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total() {
        return this.searchQuery ? this.searchedData.length : this.trafficList.length
      },
    },
    watch: {
      searchQuery(value) {
        let result = this.trafficList
        if (value !== '') {
          result = this.fuseSearch.search(this.searchQuery)
        }
        this.searchedData = result
      }
    }
  }
</script>
<style lang="scss" scoped>
.locked-traffic {
  background-color: #ccddf3;
}

.cell-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .md-button {
    margin: 3px 0;
    min-width: 100px;
  }
}

.md-table-head-label {
  padding-right: 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.md-card {
  margin: 0px 0;
}

.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.md-button+.md-button {
  margin-left: 10px;
}

.md-progress-spinner {
  margin: 18px;
  position: absolute;
  top: 25%;
  left: 45%;
  z-index: 20;
}
</style>

